<template>
  <v-dialog
    v-model="status"
    :width="width"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Relatório Analítico ({{ title }})
        <v-spacer />
        <v-btn
          text
          x-small
          fab
          @click="$emit('close')"
        >
          <v-icon color="error">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="12"
            xs="12"
            class="justify-center"
          >
            <v-card
              min-height="250"
              class="pa-3"
              outlined
            >
              <vue-apex-charts
                :options="chartOptions"
                :series="chartData"
                height="210"
              />
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="6"
            sm="12"
            xs="12"
            class="justify-center"
          >
            <v-card
              min-height="250"
              class="pa-3"
              outlined
            >
              <vue-apex-charts
                :options="chartDetailOptions"
                :series="chartDetailSeries"
                height="210"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    status: {
      default: false,
      type: Boolean,
    },
    width: {
      default: 1200,
      type: Number,
    },
    type: {
      type: String,
      default: 'sms',
    },
    details: {
      type: Array,
      default: () => [],
    },
    campaignId: {
      type: Number,
      default: null,
    },
    interval: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const ins = getCurrentInstance()?.proxy
    const $vuetify = ins && ins.$vuetify ? ins.$vuetify : null

    const chartOptions = {
      colors: [$vuetify.theme.currentTheme.info],
      chart: {
        type: 'bar',
        toolbar: { show: false },
        offsetX: -15,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          borderRadius: 5,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: { enabled: true },
      legend: { show: false },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: { fontSize: '12px' },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
        },
      },
      stroke: { width: [1, 2] },
      grid: {
        strokeDashArray: 12,
        padding: { right: 0 },
      },
    }

    const chartData = [
      {
        data: [],
      },
    ]

    const chartDetailSeries = ref([])
    const chartDetailOptions = ref({
      colors: [
        $vuetify.theme.currentTheme.info,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.success,
        $vuetify.theme.currentTheme.warning,
        $vuetify.theme.currentTheme.error,
      ],
      chart: {
        type: 'donut',
        toolbar: { show: false },
        offsetX: -15,
      },
      plotOptions: {
        pie: {
          expandOnClick: true,
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '12px',
              color: $vuetify.theme.currentTheme.primary,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: '12px',
              color: $vuetify.theme.currentTheme.primary,
              offsetY: 16,
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'right',
      },
      labels: [],
    })

    const title = ref(null)

    return {
      title,
      icons: {
        mdiClose,
      },

      chartDetailSeries,
      chartDetailOptions,

      chartOptions,
      chartData,
    }
  },

  async mounted() {
    await this.getDetail()
  },

  methods: {
    ...mapActions('reportDetail', [
      'sms',
      'voice',
      'sanitizer',
    ]),

    async getDetail() {
      const serviceTotals = new Map([
        ['SMS', []],
        ['Higienizador', []],
        ['Voz', []],
      ])

      const details = this.details.map(item => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
      }))

      details.forEach(item => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of serviceTotals.keys()) {
          if (item.name.includes(key)) {
            serviceTotals.set(key, details.filter(i => i.name.includes(key)))
          }
        }
      })

      let statusInfo = []

      if (this.type === 'sms') {
        this.title = 'SMS'
        statusInfo = await this.sms({ campaignId: this.campaignId, interval: this.interval })
      }

      if (this.type === 'sanitizer') {
        this.title = 'Higienizador'
        statusInfo = await this.sanitizer({ campaignId: this.campaignId, interval: this.interval })
      }

      if (this.type === 'voice') {
        this.title = 'Voz'
        statusInfo = await this.voice({ campaignId: this.campaignId, interval: this.interval })
      }

      const detailOptions = serviceTotals.get(this.title)

      const chartStatus = statusInfo.map(item => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
      }))

      this.chartData = [
        {
          data: chartStatus.map(item => Number(item.quantity)),
        },
      ]

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: chartStatus.map(item => item.name),
          },
        },
      }

      this.chartDetailSeries = detailOptions.map(item => Number(item.quantity))
      this.chartDetailOptions = {
        ...this.chartDetailOptions,
        ...{
          labels: detailOptions.map(item => item.name),
        },
      }
    },
  },
}
</script>

<style lang="scss">
.apexcharts-canvas {
  width: 100% !important;
}
</style>
