<template>
  <v-dialog
    v-model="status"
    :width="width"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Informações da Campanha
        <v-spacer />
        <v-btn
          text
          x-small
          fab
          @click="$emit('close')"
        >
          <v-icon color="error">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              v-model="campaign.id"
              dense
              hide-details
              label="ID"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
            sm="6"
          >
            <v-text-field
              v-model="campaign.name"
              dense
              hide-details
              label="Campanha"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-select
              v-model="campaign.serviceId"
              :items="items.services"
              dense
              hide-details
              item-text="name"
              item-value="id"
              label="Serviço"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-select
              v-model="campaign.internalStatus"
              :items="items.internalStatus"
              dense
              hide-details
              item-text="label"
              item-value="value"
              label="Status"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              :value="$day(campaign.sendDate).format('DD/MM/YYYY HH:mm:ss')"
              dense
              hide-details
              label="Data Envio"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="4"
            sm="6"
          >
            <v-text-field
              :value="campaign.userName"
              dense
              hide-details
              label="Cliente"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              :value="$money(campaign.value, 3)"
              dense
              hide-details
              label="Preço (R$)"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              :value="$money(campaign.quantity, 0)"
              dense
              hide-details
              label="Qtd Números"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              :value="$money(campaign.validQuantity, 0)"
              :label="resolveLabel(campaign.serviceId).validQuantity"
              dense
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              :value="$money(campaign.invalidQuantity, 0)"
              :label="resolveLabel(campaign.serviceId).invalidQuantity"
              dense
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col
            v-if="campaign.webhookUrl"
            cols="6"
            md="4"
            sm="6"
          >
            <v-text-field
              :value="campaign.webhookUrl"
              dense
              hide-details
              label="Webhook"
              outlined
              readonly
            />
          </v-col>
        </v-row>
        <v-row v-if="getService() === 'voice'">
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-select
              v-model="detail.typeVoice"
              :items="items.voiceTypes"
              dense
              hide-details
              item-text="text"
              item-value="value"
              label="Tipo Voz"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              :value="detail.dialSpeed"
              dense
              hide-details
              label="Velocidade Discagem"
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="6"
          >
            <v-text-field
              :value="detail.attempt"
              dense
              hide-details
              label="Tentativas"
              outlined
              readonly
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          :disabled="campaign.internalStatus != items.internalStatus[0].value"
          color="error"
          outlined
          @click="$emit('cancel', campaign.id)"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="campaign.internalStatus != items.internalStatus[2].value"
          color="primary"
          outlined
          class="px-4"
          filled
          @click="$emit('analytic', getService(), campaign.id)"
        >
          <v-icon
            left
            dark
          >
            {{ icons.mdiChartAreaspline }}
          </v-icon>
          Analítico
        </v-btn>
        <v-btn
          :disabled="campaign.internalStatus != items.internalStatus[2].value"
          color="primary"
          class="px-4"
          filled
          @click="downloadReport"
        >
          <v-icon
            left
            dark
          >
            {{ icons.mdiFileDownload }}
          </v-icon>
          Relatório
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { ref } from '@vue/composition-api'
import { mdiClose, mdiFileDownload, mdiChartAreaspline } from '@mdi/js'
import { serviceType as service, campaignStatus, voiceTypes } from '@/utils/enum'

export default {
  props: {
    status: {
      default: false,
      type: Boolean,
    },
    width: {
      default: 1500,
      type: Number,
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const detail = ref({})

    const resolveLabel = serviceId => {
      if (serviceId === service.Sanitizer) {
        return {
          validQuantity: 'Com Atividade',
          invalidQuantity: 'Sem Atividade',
        }
      }

      return {
        validQuantity: 'Enviados',
        invalidQuantity: 'Não Enviados',
      }
    }

    return {
      detail,
      resolveLabel,

      // icons
      icons: {
        mdiClose,
        mdiFileDownload,
        mdiChartAreaspline,
      },

      // items
      items: {
        services: [],
        internalStatus: campaignStatus,
        voiceTypes,
      },
    }
  },

  async created() {
    await this.getCampaignDetail(this.campaign)
    this.items.services = await this.services()
  },

  methods: {
    ...mapActions('services', {
      services: 'find',
    }),
    ...mapActions('reportServices', {
      downloadSMSReport: 'getSmsPhones',
      downloadSanitizerReport: 'getWhatsAppPhones',
      downloadVoiceReport: 'getVoicePhones',
    }),
    ...mapActions('serviceSms', {
      getSMS: 'findById',
    }),
    ...mapActions('serviceSanitizer', {
      getSanitizer: 'findById',
    }),
    ...mapActions('serviceVoice', {
      getVoice: 'findById',
    }),

    getService() {
      const { serviceId } = this.campaign

      if ([
        service.SMSShortcode,
        service.SMSToken,
        service.SMSFlash,
        service.SMSMassive,
        service.SMSBet,
      ].includes(serviceId)) {
        return 'sms'
      }

      if ([
        service.Voice30s,
        service.Voice60s,
      ].includes(serviceId)) {
        return 'voice'
      }

      if (serviceId === service.Sanitizer) {
        return 'sanitizer'
      }

      return null
    },

    async getCampaignDetail(campaign) {
      const service = this.getService()

      if (!service) return

      if (service === 'sms') {
        const response = await this.getSMS(campaign.id)
        this.detail = response.sms
      }

      if (service === 'voice') {
        const response = await this.getVoice(campaign.id)
        this.detail = response.voice
      }
    },

    async downloadReport() {
      const { id } = this.campaign
      const service = this.getService()

      if (service === 'sms') {
        this.downloadSMSReport(id)
      }

      if (service === 'sanitizer') {
        this.downloadSanitizerReport(id)
      }

      if (service === 'voice') {
        this.downloadVoiceReport(id)
      }
    },
  },
}
</script>
