<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl mb-0">
          Relatório de Serviços
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
        xs="12"
      >
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col
                v-for="service in serviceInfo"
                :key="service.title"
                cols="12"
                md="4"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  :color="resolveIconVariation (service.title).color"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ resolveIconVariation (service.title).icon }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    {{ service.title }}
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ service.total }}
                  </h3>
                  <p class="text-xs text--secondary mb-0">
                    <a
                      :disabled="service.total <= 0"
                      :class="{ 'info--text': service.total > 0 }"
                      @click="service.total > 0 ? openServiceDetailDialog(service.type) : null"
                    >
                      Ver mais...
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-fab-transition>
              <v-btn
                color="primary"
                class="white--text"
                absolute
                top
                right
                x-small
                @click="intervalDialog = true"
              >
                <v-icon
                  class="me-1"
                  size="16"
                >
                  {{ icons.mdiFilter }}
                </v-icon>
                {{ intervals.find(item => item.value === selectedInterval).label }}
              </v-btn>
            </v-fab-transition>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="3"
        md="3"
        sm="6"
        xs="12"
      >
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  :color="resolveIconVariation ('Carteira').color"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ resolveIconVariation ('Carteira').icon }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Carteira
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    R$ {{ availableLimit }}
                  </h3>
                  <p class="text-xs text--secondary mb-0">
                    <a
                      class="info--text"
                      href="/account/wallet-extract"
                      target="_blank"
                    >
                      Ver extrato...
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="3"
        md="3"
        sm="12"
        xs="12"
      >
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  :color="resolveIconVariation ('Token').color"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ resolveIconVariation ('Token').icon }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Token API
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    <v-text-field
                      :value="'*'.repeat(25)"
                      :append-icon="icons.mdiContentCopy"
                      class="mt-1"
                      outlined
                      hide-details
                      dense
                      type="text"
                      readonly
                      @click:append="copyTokenApi"
                    />
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="campaigns"
          :items-per-page="10"
          item-key="id"
          disable-sort
          dense
        >
          <!-- eslint-disable vue/valid-v-for -->
          <template
            v-for="col in headersWithFilter"
            v-slot:[`header.${col}`]="{ header }"
          >
            {{ header.text }}

            <v-btn
              color="white"
              icon
              @click="dialogFilter = true"
            >
              <v-icon small>
                {{ icons.mdiFilterMenu }}
              </v-icon>
            </v-btn>
          </template>
          <!-- eslint-disable vue/valid-v-for -->

          <template #[`item.action`]="{ item }">
            <v-btn
              color="info"
              title="Visualizar"
              icon
              @click="openCampaignDetail(item)"
            >
              <v-icon>{{ icons.mdiEye }}</v-icon>
            </v-btn>
          </template>

          <template #[`item.name`]="{ item }">
            <div
              class="text-truncate"
              style="max-width: 200px;"
            >
              {{ item.name }}
            </div>
          </template>

          <template #[`item.serviceName`]="{ item }">
            <span class="font-weight-bold">{{ item.serviceName }}</span>
          </template>

          <template #[`item.internalStatus`]="{ item }">
            <v-chip
              :color="resolveCampaignStatus(item.internalStatus).color"
              small
              label
            >
              <span class="white--text">
                {{ resolveCampaignStatus(item.internalStatus).label }}
              </span>
            </v-chip>
          </template>

          <template #[`item.userName`]="{ item }">
            <div
              class="text-truncate"
              style="max-width: 200px;"
            >
              {{ item.userName }}
            </div>
          </template>

          <template #[`item.sendDate`]="{ item }">
            <div class="d-flex flex-column my-1">
              <span>
                <v-icon size="18">
                  {{ icons.mdiCalendar }}
                </v-icon>
                {{ $day(item.sendDate).format('DD/MM/YYYY') }}
              </span>
              <span>
                <v-icon size="18">
                  {{ icons.mdiClock }}
                </v-icon>
                {{ $day(item.sendDate).format('HH:mm') }}
              </span>
            </div>
          </template>

          <template #[`item.value`]="{ item }">
            {{ $money(item.value, 3) }}
          </template>

          <template #[`item.quantity`]="{ item }">
            {{ $money(item.quantity, 0) }}
          </template>

          <template #[`item.validQuantity`]="{ item }">
            {{ $money(item.validQuantity, 0) }}
          </template>

          <template #[`item.invalidQuantity`]="{ item }">
            {{ $money(item.invalidQuantity, 0) }}
          </template>

          <template #[`item.affiliate`]="{ item }">
            <div
              class="text-truncate"
              style="max-width: 200px;"
            >
              {{ item.affiliate }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <filter-service
      :status="dialogFilter"
      :logged-in-user-is-admin="loggedInUserIsAdmin"
      @filter="filterCampaign"
      @close="dialogFilter = false"
    />

    <service-detail
      v-if="dialogServiceDetail"
      :status="dialogServiceDetail"
      :type="serviceDetailType"
      :campaign-id="serviceDetailCampaignId"
      :interval="selectedInterval"
      :details="serviceDetails"
      @close="dialogServiceDetail = false"
    />

    <campaign-detail
      v-if="dialogCampaignDetail"
      :status="dialogCampaignDetail"
      :campaign="selectedCampaign"
      @close="dialogCampaignDetail = false"
      @cancel="cancelSelectedCampaign"
      @analytic="openServiceDetailDialog"
    />

    <v-dialog
      v-model="intervalDialog"
      max-width="290"
    >
      <v-card>
        <v-card-text>
          <div
            v-for="interval in intervals"
            :key="interval.value"
            class="d-flex align-start interval-group px-3 py-5"
            @click="selectedInterval = interval.value; intervalDialog = false; getServiceDetail({ interval: interval.value })"
          >
            <v-avatar
              rounded
              size="38"
              color="#5e56690a"
              class="me-4"
            >
              <v-icon size="20">
                {{ icons.mdiCalendar }}
              </v-icon>
            </v-avatar>

            <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div>
                <h4 class="font-weight-medium">
                  {{ interval.label }}
                </h4>
                <span class="text-xs text-no-wrap">{{ interval.description }}</span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ref } from '@vue/composition-api'

import {
  mdiAccountCash,
  mdiCalendar,
  mdiCheckAll,
  mdiClock,
  mdiEye,
  mdiFilterMenu,
  mdiMessageTextOutline,
  mdiPhonePlusOutline,
  mdiContentCopy,
  mdiApi,
  mdiFilter,
} from '@mdi/js'
import { ROLE_TYPE } from '@/store/auth/mutations'
import { campaignStatus } from '@/utils/enum'
import FilterService from './filter.vue'
import CampaignDetail from './campaign-detail.vue'
import ServiceDetail from './service-detail.vue'

export default {
  components: {
    FilterService,
    CampaignDetail,
    ServiceDetail,
  },

  setup() {
    const dialogFilter = ref(false)
    const dialogCampaignDetail = ref(false)
    const dialogServiceDetail = ref(false)
    const serviceDetails = ref([])
    const serviceDetailType = ref(null)
    const availableLimit = ref('...')
    const tokenApi = localStorage.getItem('tokenApi') || ''
    const selectedCampaign = ref({})

    const intervalDialog = ref(false)
    const selectedInterval = ref('day')
    const intervals = [
      {
        value: 'day',
        label: 'Hoje',
        description: 'Resumo de hoje',
      },
      {
        value: 'week',
        label: 'Semana',
        description: 'Resumo dos últimos 7 dias',
      },
      {
        value: 'month',
        label: 'Mês',
        description: 'Resumo dos últimos 30 dias',
      },
    ]

    const serviceInfo = ref([
      {
        title: 'SMS',
        total: '...',
        type: 'sms',
      },
      {
        title: 'Voz',
        total: '...',
        type: 'voice',
      },
      {
        title: 'Higienização',
        total: '...',
        type: 'sanitizer',
      },
    ])

    const resolveIconVariation = data => {
      if (data === 'SMS') return { icon: mdiMessageTextOutline, color: 'primary' }
      if (data === 'Voz') return { icon: mdiPhonePlusOutline, color: 'primary' }
      if (data === 'Higienização') return { icon: mdiCheckAll, color: 'primary' }
      if (data === 'Carteira') return { icon: mdiAccountCash, color: 'info' }
      if (data === 'Token') return { icon: mdiApi, color: 'info' }

      return { icon: mdiPhonePlusOutline, color: 'primary' }
    }

    const resolveCampaignStatus = value => {
      const internalStatus = campaignStatus.find(item => item.value === value)

      if (internalStatus) {
        return internalStatus
      }

      return { color: 'error', label: 'Não definido' }
    }

    const headers = [
      { text: 'Ações', value: 'action', align: 'center' },
      { text: 'ID', value: 'id', align: 'center' },
      { text: 'Campanha', value: 'name' },
      { text: 'Status', value: 'internalStatus' },
      { text: 'Serviço', value: 'serviceName' },
      { text: 'Cliente', value: 'userName' },
    ]

    const loggedInUserRole = Number(Buffer.from(localStorage.getItem('role'), 'base64'))
    const loggedInUserIsAdmin = [ROLE_TYPE.admin].includes(loggedInUserRole)

    if (loggedInUserIsAdmin) {
      headers.push({ text: 'ID Afiliado', value: 'affiliateId' })
    }

    headers.push(...[
      { text: 'Data Envio', value: 'sendDate' },
      { text: 'Preço (R$)', value: 'value', align: 'right' },
      { text: 'Quantidade', value: 'quantity', align: 'right' },
    ])

    const headersWithFilter = [
      'id',
      'name',
      'internalStatus',
      'serviceName',
      'userName',
      'sendDate',
    ]

    return {
      dialogFilter,
      dialogCampaignDetail,
      dialogServiceDetail,
      serviceDetails,
      serviceDetailType,
      availableLimit,
      resolveIconVariation,
      resolveCampaignStatus,
      headers,
      headersWithFilter,
      loggedInUserIsAdmin,
      tokenApi,
      selectedCampaign,

      // icons
      icons: {
        mdiAccountCash,
        mdiCalendar,
        mdiCheckAll,
        mdiClock,
        mdiEye,
        mdiFilterMenu,
        mdiMessageTextOutline,
        mdiPhonePlusOutline,
        mdiContentCopy,
        mdiApi,
        mdiFilter,
      },

      serviceInfo,

      intervalDialog,
      selectedInterval,
      intervals,
    }
  },

  computed: {
    ...mapState('reportServices', {
      campaigns: state => state.rows,
    }),
  },

  async created() {
    await this.fetchReportServices()
    await this.getUserExtract()

    await this.getServiceDetail()
  },

  methods: {
    ...mapActions('reportServices', [
      'find',
      'cancelCampaign',
    ]),

    ...mapActions('walletExtract', {
      userExtract: 'find',
    }),

    ...mapActions('reportDetail', [
      'getDetails',
    ]),

    copyTokenApi() {
      navigator.clipboard.writeText(this.tokenApi)
        .then(() => {
          this.$notify('Token API copiado para a área de transferência', 'success')
        })
        .catch(err => {
          this.$notify('Erro ao copiar Token API', 'error')
        })
    },

    openServiceDetailDialog(type, campaignId = null) {
      this.serviceDetailType = type
      this.serviceDetailCampaignId = campaignId
      this.dialogServiceDetail = true
    },

    openCampaignDetail(campaign) {
      this.dialogCampaignDetail = true
      this.selectedCampaign = campaign
    },

    async getServiceDetail(params = {}) {
      const details = await this.getDetails(params)
      const serviceTotals = new Map([
        ['SMS', 0],
        ['Higienizador', 0],
        ['Voz', 0],
      ])

      this.serviceDetails = details.map(item => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
      }))

      this.serviceDetails.forEach(item => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of serviceTotals.keys()) {
          if (item.name.includes(key)) {
            serviceTotals.set(key, serviceTotals.get(key) + Number(item.quantity))
          }
        }
      })

      this.serviceInfo[0].total = serviceTotals.get('SMS')
      this.serviceInfo[1].total = serviceTotals.get('Voz')
      this.serviceInfo[2].total = serviceTotals.get('Higienizador')
    },

    async fetchReportServices(params = {}) {
      await this.find(params)
    },

    async filterCampaign(params) {
      await this.fetchReportServices(params)
    },

    async getUserExtract() {
      const extract = await this.userExtract()
      this.availableLimit = this.$money(String(extract.total.availableLimit))
    },

    async cancelSelectedCampaign(campaignId) {
      await this.cancelCampaign(campaignId)
      await this.filterCampaign()

      this.dialogCampaignDetail = false
    },
  },
}
</script>

<style>
.interval-group:hover {
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
</style>
