<template>
  <v-dialog
    v-model="status"
    :width="width"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Pesquisar campanhas
        <v-spacer />
        <v-btn
          text
          x-small
          fab
          @click="$emit('close')"
        >
          <v-icon color="error">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <v-text-field
              v-model="campaignId"
              clearable
              label="ID"
              hide-details
              outlined
              dense
              @keypress.enter="filterCampaign"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <v-text-field
              v-model="campaignName"
              clearable
              label="Campanha"
              hide-details
              outlined
              dense
              @keypress.enter="filterCampaign"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <v-autocomplete
              v-model="internalStatus"
              :items="items.internalStatus"
              clearable
              dense
              placeholder="Todos"
              label="Status"
              outlined
              hide-details
              item-text="label"
              item-value="value"
              @change="filterCampaign"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <v-autocomplete
              v-model="serviceId"
              :items="items.services"
              clearable
              placeholder="Todos"
              label="Serviço"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              @change="filterCampaign"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <v-autocomplete
              v-model="userId"
              :disabled="!loggedInUserIsAdmin"
              :items="items.users"
              clearable
              dense
              label="Usuário"
              outlined
              hide-details
              item-text="text"
              item-value="value"
              @change="filterCampaign"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <date
              v-model="sendDateStart"
              :type="'date'"
              outlined
              hide-details
              label="Data Envio (Início)"
              readonly
              dense
              @input="filterCampaign"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <date
              v-model="sendDateEnd"
              :type="'date'"
              outlined
              dense
              hide-details
              label="Data Envio (Fim)"
              readonly
              @input="filterCampaign"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="error"
          outlined
          @click="resetFilter"
        >
          Limpar filtro
        </v-btn>

        <v-btn
          color="info"
          @click="filterCampaign"
        >
          Pesquisar
          <v-icon
            right
            dark
          >
            {{ icons.mdiMagnify }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { ref } from '@vue/composition-api'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { campaignStatus } from '@/utils/enum'

export default {
  props: {
    status: {
      default: false,
      type: Boolean,
    },
    loggedInUserIsAdmin: {
      type: Boolean,
    },
    width: {
      default: 1000,
      type: Number,
    },
  },

  setup() {
    const campaignId = ref(null)
    const campaignName = ref(null)
    const internalStatus = ref(null)
    const serviceId = ref(null)
    const userId = ref(null)
    const sendDateStart = ref(null)
    const sendDateEnd = ref(null)

    return {
      campaignId,
      campaignName,
      internalStatus,
      serviceId,
      userId,
      sendDateStart,
      sendDateEnd,

      // icons
      icons: {
        mdiClose,
        mdiMagnify,
      },

      // items
      items: {
        users: ref([]),
        services: ref([]),
        internalStatus: campaignStatus,
      },
    }
  },

  async mounted() {
    setInterval(async () => {
      await this.filterCampaign()
    }, 60000)
  },

  async created() {
    this.items.services = await this.services()

    if (this.loggedInUserIsAdmin) {
      this.items.users = await this.getUsers()
    }
  },

  methods: {
    ...mapActions('services', {
      services: 'find',
    }),

    ...mapActions('user', {
      users: 'find',
    }),

    resetFilter() {
      this.campaignId = null
      this.campaignName = null
      this.internalStatus = null
      this.serviceId = null
      this.userId = null
      this.sendDateStart = null
      this.sendDateEnd = null

      this.$emit('filter', {})
    },

    async getUsers() {
      const users = await this.users()

      return users.map(user => ({
        value: user.id,
        text: `${user.id} - ${user.name}`,
      }))
    },

    async filterCampaign() {
      this.$emit('filter', {
        campaignId: this.campaignId || null,
        campaignName: this.campaignName || null,
        internalStatus: this.internalStatus || null,
        serviceId: this.serviceId || null,
        userId: this.userId || null,
        sendDateStart: this.sendDateStart ? this.$day(this.sendDateStart, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        sendDateEnd: this.sendDateEnd ? this.$day(this.sendDateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      })
    },
  },
}
</script>
